import React from "react";
import { graphql } from "gatsby";

const ProductPage = ({
  data: {
    gcms: { product },
  },
}) => (
  <React.Fragment>
    <h1>{product.name}</h1>
    <p>{product.description}</p>
  </React.Fragment>
);

export const pageQuery = graphql`
  query ProdutPageQuery($id: ID!) {
    gcms {
      product(where: { id: $id }) {
        name
        description
        slug
      }
    }
  }
`;

export default ProductPage;
